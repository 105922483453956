import {Config} from "@/types/config";
import dev from "./dev.env";
import prod from "./prod.env";

const env = process.env.NODE_ENV;
let config: Config;
if (env === "dev") {
    config = dev()
} else {
    config = prod()
}

export default config;
