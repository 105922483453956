<template>
  <div class="sidebar">
    <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#324157"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router>
      <Page :items="items"/>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import Page from "@/components/Page.vue";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import axios from "axios";

const collapse=computed(():boolean=>{
  return false;
  //return useStore().state.collapse;
});

const onRoutes="/"

const items=ref([
  {
    icon: "el-icon-s-home",
    page: "/dashboard",
    title: "系统首页"
  }
]);
onMounted(()=>{
 axios.get("system/login/page").then((res: { data: { code: number, data: any } }) => {
    console.log(res);
    if (res.data.code === 0) {
       items.value = [{
          icon: "el-icon-s-home",
          page: "/dashboard",
          title: "系统首页"
      }].concat(res.data.data);
    }
  })
})
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
  text-align: left;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}

.sidebar > ul {
  height: 100%;
}
</style>
