<template>
  <div class="tags">
    <template v-for="(item,index) in tagList" :key="item.name">
      <el-tag :effect="isActive(item.path)?'dark':'light'" closable
              @close="closeTags(index)" style="margin: 3px 5px 2px 3px;cursor: pointer"
              @click="to(item)"
      > {{ item.title }}
      </el-tag>
    </template>
    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <el-button size="small" type="primary">
          标签选项
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu size="small">
            <el-dropdown-item command="other">关闭其他</el-dropdown-item>
            <el-dropdown-item command="all">关闭所有</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, Ref, ref, watch} from "vue";
import {useRoute} from "vue-router";
import router from "@/router";
import {RouteLocationNormalizedLoaded} from "_vue-router@4.3.2@vue-router";

interface Path {
  name: string,
  title: string,
  path: string
}

interface DataType {
  tagList: Array<Path>
}

const tagList: Ref<Array<Path>> = ref([]);

const showTags = computed(() => {
  return tagList.value.length > 0;
})
const route = useRoute();

console.log("route",route)

function isActive(path: string) {
  return path === route.fullPath;
}

function to(item: Path) {
  router.push({path: item.path});
}

function closeTags(index: number) {
  const delItem = tagList.value[index];
  tagList.value.splice(index, 1)
  const item = tagList.value[index] ? tagList.value[index] : tagList.value[index - 1];
  if (item) {
    delItem.path === route.fullPath && router.push(item.path);
  } else {
    router.push("/");
  }
}

function closeAll() {
  tagList.value = [];
  router.push("/");
}

function closeOther() {
  tagList.value = tagList.value.filter((item: Path) => {
    return item.path === route.fullPath;
  });
}

function setTags(route: RouteLocationNormalizedLoaded) {
  console.log(route)
  const isExist = tagList.value.some((item: Path) => {
    return item.path === route.fullPath;
  });
  if (!isExist) {
    console.log("添加", route)

    tagList.value.push({
      name: route.name as string,
      title: route.meta.title as string,
      path: route.fullPath
    });
    console.log(tagList.value);
  }
}

function handleTags(command: string) {
  command === "other" ? closeOther() : closeAll();
}

watch(route,(newValue:RouteLocationNormalizedLoaded)=>{
  console.log("watch route before")
  setTags(newValue);
  console.log("watch route after")
})

onMounted(()=>{
   setTags(route);
})
</script>


<style>
.tags {
  position: relative;
  height: 30px;
  overflow: hidden;
  background: #fff;
  padding-right: 120px;
  box-shadow: 0 5px 10px #ddd;
  text-align: left;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 30px;
  background: #fff;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>
