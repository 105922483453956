import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar" }

import Page from "@/components/Page.vue";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import axios from "axios";

const onRoutes="/"


export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  setup(__props) {

const collapse=computed(():boolean=>{
  return false;
  //return useStore().state.collapse;
});

const items=ref([
  {
    icon: "el-icon-s-home",
    page: "/dashboard",
    title: "系统首页"
  }
]);
onMounted(()=>{
 axios.get("system/login/page").then((res: { data: { code: number, data: any } }) => {
    console.log(res);
    if (res.data.code === 0) {
       items.value = [{
          icon: "el-icon-s-home",
          page: "/dashboard",
          title: "系统首页"
      }].concat(res.data.data);
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      class: "sidebar-el-menu",
      "default-active": onRoutes,
      collapse: collapse.value,
      "background-color": "#324157",
      "text-color": "#bfcbd9",
      "active-text-color": "#20a0ff",
      "unique-opened": "",
      router: ""
    }, {
      default: _withCtx(() => [
        _createVNode(Page, { items: items.value }, null, 8, ["items"])
      ]),
      _: 1
    }, 8, ["collapse"])
  ]))
}
}

})