import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tags" }
const _hoisted_2 = { class: "tags-close-box" }

import {computed, onMounted, Ref, ref, watch} from "vue";
import {useRoute} from "vue-router";
import router from "@/router";
import {RouteLocationNormalizedLoaded} from "_vue-router@4.3.2@vue-router";

interface Path {
  name: string,
  title: string,
  path: string
}

interface DataType {
  tagList: Array<Path>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Tags',
  setup(__props) {

const tagList: Ref<Array<Path>> = ref([]);

const showTags = computed(() => {
  return tagList.value.length > 0;
})
const route = useRoute();

console.log("route",route)

function isActive(path: string) {
  return path === route.fullPath;
}

function to(item: Path) {
  router.push({path: item.path});
}

function closeTags(index: number) {
  const delItem = tagList.value[index];
  tagList.value.splice(index, 1)
  const item = tagList.value[index] ? tagList.value[index] : tagList.value[index - 1];
  if (item) {
    delItem.path === route.fullPath && router.push(item.path);
  } else {
    router.push("/");
  }
}

function closeAll() {
  tagList.value = [];
  router.push("/");
}

function closeOther() {
  tagList.value = tagList.value.filter((item: Path) => {
    return item.path === route.fullPath;
  });
}

function setTags(route: RouteLocationNormalizedLoaded) {
  console.log(route)
  const isExist = tagList.value.some((item: Path) => {
    return item.path === route.fullPath;
  });
  if (!isExist) {
    console.log("添加", route)

    tagList.value.push({
      name: route.name as string,
      title: route.meta.title as string,
      path: route.fullPath
    });
    console.log(tagList.value);
  }
}

function handleTags(command: string) {
  command === "other" ? closeOther() : closeAll();
}

watch(route,(newValue:RouteLocationNormalizedLoaded)=>{
  console.log("watch route before")
  setTags(newValue);
  console.log("watch route after")
})

onMounted(()=>{
   setTags(route);
})

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tagList.value, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        key: item.name,
        effect: isActive(item.path)?'dark':'light',
        closable: "",
        onClose: ($event: any) => (closeTags(index)),
        style: {"margin":"3px 5px 2px 3px","cursor":"pointer"},
        onClick: ($event: any) => (to(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.title), 1)
        ]),
        _: 2
      }, 1032, ["effect", "onClose", "onClick"]))
    }), 128)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_dropdown, { onCommand: handleTags }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, { size: "small" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_dropdown_item, { command: "other" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("关闭其他")
                ])),
                _: 1
              }),
              _createVNode(_component_el_dropdown_item, { command: "all" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("关闭所有")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            size: "small",
            type: "primary"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" 标签选项 "),
              _createElementVNode("i", { class: "el-icon-arrow-down el-icon--right" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})