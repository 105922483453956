import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import qs from "qs";
import lodash from "lodash";
import {onMounted, reactive, ref, toRefs, watch} from "vue";
import axios from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
  filter: {
    type: Function,
    default: null
  },
  pageSize: {
    type: Number,
    default: 50
  },
  enablePage: {
    type: Boolean,
    default: true,
  },
  url: {
    type: String,
    default: null
  },
  param: {
    type: Object,
    default() {
      return null
    }
  }
},
  setup(__props, { expose: __expose }) {

const props = __props;
const {url, param} = toRefs(props);
const emptyText = ref('');
const loading = ref(false);
const data = reactive({data: [], count: -1});
const count = ref(10);
const page = ref(1);
const limit = ref(props.pageSize);
const selection = ref([]);

onMounted(() => {
  query();
})

watch(page, (old, nv) => {
  console.log("page change", old, nv)
  query()
})

watch(url, (val) => {
  console.log(val)
  console.log("watch:url")
  query();
})

watch(param, lodash.debounce(() => {
      console.log("watch:param", param.value)
      page.value = 1;
      query();
    }, 200),
    {deep: true})

function select(selections) {
  selection.value = selections;
}

function selectAll(selections) {
  selection.value = selections;
}

function sizeChange(size) {
  console.log("sizeChange", size)
  limit.value = size;
  if (page.value * size <= data.count) {
    query();
  }
}

function reload() {
  query();
}

function deleteByIndex(index) {
  data.data.splice(index, 1);
}

function getData() {
  return data.data
}

function query() {
  if (url?.value) {
    let httpParam = param.value ? {...param.value} : {};
    httpParam.page = page.value;
    httpParam.limit = limit.value;
    loading.value = true;
    axios.post(url.value, qs.stringify(httpParam))
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            data.data = res.data.data;
            if (res.data.count && res.data.count > 0) {
              count.value = res.data.count;
            }
            selection.value = []
            emptyText.value = '暂无数据';
          } else {
            data.data = []
            emptyText.value = res.data.msg;
          }
          loading.value = false;
        }).catch(err => {
      console.log(err);
      loading.value = false
    })
  }
}

__expose({
  getData,
  deleteByIndex,
  reload,
  data: data,
  selection: selection,
  query
})


return (_ctx: any,_cache: any) => {
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "top"),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      ref: "table",
      emptyText: emptyText.value,
      data: __props.filter?data.data.filter(__props.filter):data.data,
      stripe: true,
      border: true,
      "highlight-current-row": true,
      onSelect: select,
      onSelectAll: selectAll,
      "max-height": "550",
      style: {"width":"100%","padding":"5px"}
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["emptyText", "data"])), [
      [_directive_loading, loading.value]
    ]),
    (__props.enablePage)
      ? (_openBlock(), _createBlock(_component_el_pagination, {
          key: 0,
          background: "",
          "hide-on-single-page": true,
          "page-sizes": [10,20,30,40,50, 100, 200, 300, 400],
          "page-size": limit.value,
          onSizeChange: sizeChange,
          currentPage: page.value,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((page).value = $event)),
          layout: "prev, pager, next , total , sizes , jumper",
          total: count.value
        }, null, 8, ["page-size", "currentPage", "total"]))
      : _createCommentVNode("", true)
  ]))
}
}

})