<template>
  <template v-for="item in props.items">
    <template v-if="item.itemList && item.itemList.length>0">
      <el-sub-menu :index="item.title " :key="item.title ">
        <template #title>
          <el-icon><Folder /></el-icon>
          <span>{{ item.title }}</span>
        </template>
        <Page :items="item.itemList"/>
      </el-sub-menu>
    </template>
    <template v-else>
      <el-menu-item :index="item.page" :key="item.page">
        <i :class="item.icon"></i>
        <template #title>{{ item.title }}</template>
      </el-menu-item>
    </template>
  </template>
</template>

<script lang="ts" setup>
import {Folder} from "@element-plus/icons-vue";
const props=defineProps(["items"]);
console.log(props);
</script>

<style scoped>

</style>
