import {createStore} from 'vuex'
import {State} from "@/types/state";
import {User} from "@/types/user";
import axios from "axios";

const state: State = ((): State => {
    let user: User | null;
    const userJson = sessionStorage.getItem("user");
    if (userJson) {
        user = JSON.parse(userJson);
    } else {
        user = null;
    }
    return {
        projectName: "井雨系统",
        token: sessionStorage.getItem("token") || "",
        user,
        //左边是否展开
        collapse: false
    }
})();

export default createStore({
    state,
    mutations: {
        login(state, {token, user}) {
            state.token = token;
            state.user = user;
            axios.defaults.headers.common.token=token;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("user", JSON.stringify(user));
        },
        loginOut(state) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.token = null;
            state.user = null;
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            axios.defaults.headers.common.token='';
        },
        // 侧边栏折叠
        handleCollapse(state, data) {
            state.collapse = data;
        }
    },
    actions: {},
    modules: {}
})
