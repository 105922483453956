<template>
  <div>
    <el-dialog v-model="dialogVisible"
               title="修改密码"
               width="30%"
               :close-on-press-escape="false"
               :close-on-click-modal="false" >
      <el-form :model="param" :rules="rules" ref="form" label-position="top" class="ms-content">
        <el-form-item prop="password" label="原始密码">
          <el-input
              type="password"
              placeholder="原始密码"
              v-model="param.password">
            <template #prepend>
              <el-button icon="Lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="newPwd" label="新密码">
          <el-input
              type="password"
              placeholder="新密码"
              v-model="param.newPwd">
            <template #prepend>
              <el-button icon="Lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="newPwd2" label="再次输入新密码">
          <el-input
              type="password"
              placeholder="再次输入新密码"
              v-model="param.newPwd2">
            <template #prepend>
              <el-button icon="Lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="update" :loading="updateIng">
            修改
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {md5} from "md5js";
import {AxiosBaseResponse, AxiosDataResponse, DataResponse} from "@/types/response";
import qs from "qs";
import {Action} from "element-plus";

@Options({
  name: "UpdatePwd",
  data() {
    return {
      dialogVisible: false,
      updateIng: false,
      param: {
        password: '',
        newPwd: '',
        newPwd2: ''
      },
      rules: {
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {min: 6, max: 20, message: '6-20位', trigger: 'blur'},
        ],
        newPwd: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {min: 6, max: 20, message: '6-20位', trigger: 'blur'},
        ],
        newPwd2: [
          {required: true, message: '请再次输入新密码', trigger: 'blur'},
          {validator: this.checkNewPwd, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    checkNewPwd(rule: any, value: any, callback: any) {
      if (value === this.param.newPwd) {
        callback();
      } else {
        callback(new Error("两次密码不一致"))
      }
    },
    show() {
      this.dialogVisible = true;
      this.param = {
        password: '',
        newPwd: '',
        newPwd2: ''
      };
    },
    update() {
      this.$refs.form.validate(async (validate: boolean) => {
        if (!validate) {
          return;
        }
        this.updateIng = true;
        try {
          let data: AxiosDataResponse<number> = await this.axios.get("system/login/getTime");
          if (data.data.code !== 0) {
            throw data.data.msg
          }
          const time = data.data.data;
          const passwordMd5 = md5(this.param.password, 32);
          const newPasswordMd5 = md5(this.param.newPwd, 32);
          let pwd = '';
          for (let n = 0; n < 32; n++) {
            let a = passwordMd5.charAt(n);
            let b = newPasswordMd5.charAt(n);
            let c = (parseInt(a, 16) ^ parseInt(b, 16)).toString(16);
            pwd += c;
          }
          const sign = md5(passwordMd5 + time + newPasswordMd5, 32);
          // time
          // pwd= oleMd5^newMd5
          // sign=md5(oldMd5+time+newMd5)
          let update: AxiosBaseResponse = await this.axios.post("system/staff/updatePwd", qs.stringify({
            pwd,
            time,
            sign
          }));
          if (update.data.code === 0) {
            this.$alert("密码修改成功,请重新登录！", "修改成功", {
              callback: (action: Action) => {
                this.$store.commit("loginOut")
                this.$router.push("/login");
              }
            });
            this.dialogVisible = false;
          } else {
            throw update.data.msg
          }
        } catch (e) {
          this.$alert(e || "密码修改失败", "错误");
        } finally {
          this.updateIng = false;
        }
      });
    }
  }
})
export default class UpdatePwd extends Vue {
}
</script>


<style scoped>

</style>
