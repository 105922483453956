import qs from "qs";
import {App} from "@vue/runtime-core";
import {RequestParam} from "@/types/requestParam";
import axios, {AxiosResponse} from "axios";

/**
 * 获取对象的子集
 */
Object.defineProperty(Object.prototype, "subset", {
    value: function (keys: Array<string>) {
        const result: Record<string, any> = {};
        for (let n = 0; n < keys.length; n++) {
            const key = keys[n];
            result[key] = this[key]
        }
        return result;
    }
})
// 秒  转成     分钟:秒 的格式
Object.defineProperty(Number.prototype, "toSecond", {
    value: function () {
        const num = Math.floor(this);
        const second = num % 60;
        const min = (num - second) / 60;
        return [min, second].map(v => {
            return (v < 10 ? '0' : '') + v
        }).join(":")
    }
})


export default (app: App) => {
    app.config.globalProperties.$objectValue = function (obj: any, key: string) {
        const keyArr = key.split(".");
        for (const n in keyArr) {
            const item = keyArr[n];
            if (obj == null) {
                return null;
            }
            obj = obj[item];
        }
        return obj;
    }

    app.config.globalProperties.$post = function ({
                                                      url, data, loading,
                                                  }: RequestParam) {
        return this.$request("post", {
            url,
            data: qs.stringify(data),
            loading
        })
    }
    app.config.globalProperties.$json = function ({
                                                      url, data, loading,
                                                  }: RequestParam) {
        return this.$request("post", {
            url,
            data,
            loading
        })
    }
    app.config.globalProperties.$request = function (method: 'get'|'post', {
        url, data, loading
    }: RequestParam) {
        let loadingDialog: { close: () => void; } | null = null;
        if (loading !== undefined && this.$loading) {
            loadingDialog = this.$loading({
                lock: true,
                text: loading || '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }
        const that = this;
        return new Promise((resolve, reject) => {
            if(method==='get'){
                that.axios.get(url, data).then((res: AxiosResponse) => {
                    if (loadingDialog) {
                        loadingDialog.close();
                    }
                    resolve(res)
                }).catch((err: any) => {
                    if (loadingDialog) {
                        loadingDialog.close();
                    }
                    reject(err);
                })
            }else if(method==='post'){
                that.axios[method](url, data).then((res: AxiosResponse) => {
                    if (loadingDialog) {
                        loadingDialog.close();
                    }
                    resolve(res)
                }).catch((err: any) => {
                    if (loadingDialog) {
                        loadingDialog.close();
                    }
                    reject(err);
                })
            }
        });
    }
    app.config.globalProperties.$get = function ({
                                                     url,  loading,
                                                 }: RequestParam) {
        return this.$request("get", {
            url,
            loading
        })
    }
}
