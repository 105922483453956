import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import installElementPlus from './plugins/element'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import axiosConfig from "./plugins/axiosConfig";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import common from "./plugins/common";
import Table from "@/components/Table.vue"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
installElementPlus(app)
axiosConfig(app)
common(app)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component("Table", Table);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
const pinia: {// @ts-ignore
    install: PluginInstallFunction
} = createPinia();
app.use(pinia);
app.use(store).use(router).mount('#app')


