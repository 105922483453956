import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

import {Folder} from "@element-plus/icons-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Page',
  props: ["items"],
  setup(__props) {

const props=__props;
console.log(props);

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Page = _resolveComponent("Page", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.items, (item) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      (item.itemList && item.itemList.length>0)
        ? (_openBlock(), _createBlock(_component_el_sub_menu, {
            index: item.title ,
            key: item.title 
          }, {
            title: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Folder))
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(item.title), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Page, {
                items: item.itemList
              }, null, 8, ["items"])
            ]),
            _: 2
          }, 1032, ["index"]))
        : (_openBlock(), _createBlock(_component_el_menu_item, {
            index: item.page,
            key: item.page
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(item.icon)
              }, null, 2)
            ]),
            _: 2
          }, 1032, ["index"]))
    ], 64))
  }), 256))
}
}

})