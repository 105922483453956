import ElementPlus from 'element-plus'
import '../element-variables.scss'
import {App} from "@vue/runtime-core";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (app:App) => {
  app.use(ElementPlus,{
    locale: zhCn,
  })
}
