<template>
  <div>
    <slot name="top"/>
    <el-table
        v-loading="loading"
        ref="table"
        :emptyText="emptyText"
        :data="filter?data.data.filter(filter):data.data"
        :stripe="true"
        :border="true"
        :highlight-current-row="true"
        @select="select"
        @select-all="selectAll"
        max-height="550"
        style="width: 100%;padding: 5px;">
      <slot/>
    </el-table>
    <template v-if="enablePage">
      <el-pagination
          background
          :hide-on-single-page="true"
          :page-sizes="[10,20,30,40,50, 100, 200, 300, 400]"
          :page-size="limit"
          @size-change="sizeChange"
          v-model:currentPage="page"
          layout="prev, pager, next , total , sizes , jumper"
          :total="count"/>
    </template>
  </div>
</template>

<script lang="ts" setup>
import qs from "qs";
import lodash from "lodash";
import {onMounted, reactive, ref, toRefs, watch} from "vue";
import axios from "axios";

const props = defineProps({
  filter: {
    type: Function,
    default: null
  },
  pageSize: {
    type: Number,
    default: 50
  },
  enablePage: {
    type: Boolean,
    default: true,
  },
  url: {
    type: String,
    default: null
  },
  param: {
    type: Object,
    default() {
      return null
    }
  }
});
const {url, param} = toRefs(props);
const emptyText = ref('');
const loading = ref(false);
const data = reactive({data: [], count: -1});
const count = ref(10);
const page = ref(1);
const limit = ref(props.pageSize);
const selection = ref([]);

onMounted(() => {
  query();
})

watch(page, (old, nv) => {
  console.log("page change", old, nv)
  query()
})

watch(url, (val) => {
  console.log(val)
  console.log("watch:url")
  query();
})

watch(param, lodash.debounce(() => {
      console.log("watch:param", param.value)
      page.value = 1;
      query();
    }, 200),
    {deep: true})

function select(selections) {
  selection.value = selections;
}

function selectAll(selections) {
  selection.value = selections;
}

function sizeChange(size) {
  console.log("sizeChange", size)
  limit.value = size;
  if (page.value * size <= data.count) {
    query();
  }
}

function reload() {
  query();
}

function deleteByIndex(index) {
  data.data.splice(index, 1);
}

function getData() {
  return data.data
}

function query() {
  if (url?.value) {
    let httpParam = param.value ? {...param.value} : {};
    httpParam.page = page.value;
    httpParam.limit = limit.value;
    loading.value = true;
    axios.post(url.value, qs.stringify(httpParam))
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            data.data = res.data.data;
            if (res.data.count && res.data.count > 0) {
              count.value = res.data.count;
            }
            selection.value = []
            emptyText.value = '暂无数据';
          } else {
            data.data = []
            emptyText.value = res.data.msg;
          }
          loading.value = false;
        }).catch(err => {
      console.log(err);
      loading.value = false
    })
  }
}

defineExpose({
  getData,
  deleteByIndex,
  reload,
  data: data,
  selection: selection,
  query
})

</script>

<style scoped>

</style>
